
import { defineComponent, ref } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import ValidationPasswordField from "@/components/atomics/ValidationPasswordField.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import { RepositoryFactory, AuthRepository } from "@/lib/https";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Signup",
  components: {
    ValidationTextField,
    ValidationPasswordField,
    FlatButton,
  },
  props: {
    // isSentConfirmMail: { type: Boolean, default: false },
  },
  setup() {
    const router = useRouter();

    const email = ref("");
    const { signUp } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

    const { formHandle } = useFormHandler(
      {
        email: email.value,
        password: "",
        repeatPassword: "",
      },
      signUp
    );
    const submit = async function () {
      const response = await formHandle();
      if (response) {
        router.push({ name: "AdminManagement" });
      }
    };

    return {
      submit,
      email,
    };
  },
});
